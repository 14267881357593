.App {
    text-align: center;
}

.App-logo {
    pointer-events: none;
}

a {
    color: #aaaa00;
    font-weight: 600;
}

a:hover {
    color: #dddd00;
}

a:active {
    color: #aaaa00;
}

.App-header {
    background-color: #282c34;
    background-image: url('/public/bg-pattern.png');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.NoticeBox {
    margin-top: 50px;
    max-width: 500px;
    font-size: 65%;
    text-align: left;
    background-color: #222222;
    padding: 20px;
    opacity: 0.8;
    border: 0px solid #bbbb00;
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-top-width: 1px;
}

.InfoFooter {
    font-size: 50%;
}

.App-link {
    color: #61dafb;
}

.SoMeImg {
    display: inline-flex;
    padding: 2px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
