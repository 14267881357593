@font-face {
    font-family: "goodtimes";
    src: local('goodtimes') url("fonts/goodtimes.otf") format("opentype");
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./fonts/OpenSans-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Poppins';
    src: local('OpenSans'), url(./fonts/Poppins-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Poppins-Bold';
    src: local('OpenSans'), url(./fonts/Poppins-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Poppins-Light';
    src: local('OpenSans'), url(./fonts/Poppins-Light.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

body {
    margin: 0;
    font-family: 'OpenSans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1f2228;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #FFFFFF12;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FFFFFF25;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FFFFFF10;
  }

  .MuiList-root {
    background-color: #1f2228 !important;
  }

  .MuiDialogActions-root {
    background-color: #1f2228 !important;
  }

  .MuiStepIcon-text {
    fill: #FFFFFF !important;
  }

  .MuiStepLabel-label {
    min-height: 50px;
  }